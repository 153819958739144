import { ref, reactive } from "vue";

import { getVt, format } from "@/stores/var";
import { coordinates, lonlat, loadPano, getItems, spotGroup, other_panos_group } from "@/stores/panorama";
import { vectorMeasurement } from "@/stores/measurement";
let state_remove = false;

import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import OSM from "ol/source/OSM";
import { defaults } from "ol/control";
import { fromLonLat, transform } from "ol/proj";
import ImageWMS from "ol/source/ImageWMS";
import ImageLayer from "ol/layer/Image";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import { bbox } from "ol/loadingstrategy";

export let draw = null;

export const mapPanelOne = ref(null);
export const selectedRadio = ref("openStreetMap");
export const isLayerShow = reactive({
  panorama: true,
  detection: false,
  mapSpot: false,
});
let zoom = 18;

export const layers = reactive({
  initTileLayer: new TileLayer({
    source: new OSM(),
  }),
  jalan: new ImageLayer({
    title: "jalan",
    source: new ImageWMS({
      url: "https://geoserver.observer.co.id/geoserver/batulicin/wms",
      params: {
        LAYERS: "batulicin:jalan_wgs84",
        TILED: true,
        exceptions: "application/vnd.ogc.se_inimage",
      },
      serverType: "geoserver",
    }),
  }),
  bangunan: new ImageLayer({
    title: "bangunan",
    source: new ImageWMS({
      url: "https://geoserver.observer.co.id/geoserver/batulicin/wms",
      params: {
        LAYERS: "batulicin:bangunan_wgs84",
        TILED: true,
        exceptions: "application/vnd.ogc.se_inimage",
      },
      serverType: "geoserver",
    }),
  }),
  panorama: new ImageLayer({
    title: "panorama",
    zIndex: 1000,
    source: new ImageWMS({
      url: "https://geoserver.observer.co.id/geoserver/observer/wms",
      params: {
        LAYERS: "observer:v_panorama_no_detection",
        TILED: true,
        exceptions: "application/vnd.ogc.se_inimage",
      },
      serverType: "geoserver",
    }),
  }),
  detection: new ImageLayer({
    title: "detection",
    zIndex: 1001,
    source: new ImageWMS({
      url: "https://geoserver.observer.co.id/geoserver/observer/wms",
      params: {
        LAYERS: "observer:v_panorama_detection",
        TILED: true,
        exceptions: "application/vnd.ogc.se_inimage",
      },
      serverType: "geoserver",
    }),
  }),
  mapSpot: new VectorLayer({
    title: "map spot",
    projection: "EPSG:4326",
    source: new VectorSource({
      format: new GeoJSON(),
      loader: function (extent, success, failure) {
        const context_extent = mapPanelOne.value.getView().calculateExtent(mapPanelOne.value.getSize());
        const llc = new transform([context_extent[0], context_extent[1]], "EPSG:4326", "EPSG:4326");
        const urc = new transform([context_extent[2], context_extent[3]], "EPSG:4326", "EPSG:4326");
        const filters = format("?as_geojson=true&bbox_string=%%,%%,%%,%%", llc[0], llc[1], urc[0], urc[1]);

        const vectorSource = this;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", "https://backend.observer.co.id/image_objects/" + filters);
        xhr.setRequestHeader("Authorization", "Bearer " + getVt());
        const onError = function () {
          vectorSource.removeLoadedExtent(extent);
          failure();
        };
        xhr.onload = function () {
          if (xhr.status == 200) {
            const features = vectorSource.getFormat().readFeatures(xhr.responseText, {
              featureProjection: "EPSG:3857",
            });
            vectorSource.addFeatures(features);
            // success(features);
          } else {
            onError();
          }
        };
        xhr.send();
      },
      strategy: bbox,
    }),
    style: new Style({
      stroke: new Stroke({
        color: "blue",
        width: 1,
      }),
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.3)",
      }),
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: "/point4.png",
      }),
    }),
  }),
});

export function updateContext() {
  zoom = mapPanelOne.value.getView().getZoom();
  const context_extent = mapPanelOne.value.getView().calculateExtent(mapPanelOne.value.getSize());
  const llc = transform([context_extent[0], context_extent[1]], "EPSG:4326", "EPSG:4326");
  const urc = transform([context_extent[2], context_extent[3]], "EPSG:4326", "EPSG:4326");

  const filters = format("?as_geojson=true&bbox_string=%%,%%,%%,%%", llc[0], llc[1], urc[0], urc[1]);

  getItems("image_objects", filters).then(mapSpotsLoaded);
}

export function mapSpotsLoaded(context_details) {
  const features = new GeoJSON().readFeatures(context_details, {
    featureProjection: "EPSG:3857",
  });

  const spotSource = new VectorSource({
    features: features,
    format: new GeoJSON(),
  });

  layers.mapSpot.setSource(spotSource);
  mapPanelOne.value.removeLayer(layers.mapSpot);
  mapPanelOne.value.addLayer(layers.mapSpot);
}

export function initMap() {
  mapPanelOne.value = new Map({
    target: "mapPanelOne",
    layers: [layers.initTileLayer, vectorMeasurement],
    controls: new defaults({
      attribution: false,
      zoom: false,
    }),
    view: new View({
      // center: new fromLonLat([116.002, -3.40945]),
      center: new fromLonLat([coordinates.lon, coordinates.lat]),
      zoom: zoom,
    }),
  });
  mapOnPointerMove();
  mapOnSingleClick();
}

function mapOnPointerMove() {
  mapPanelOne.value.on("pointermove", function (evt) {
    mapPanelOne.value.getTargetElement().style.cursor = mapPanelOne.value.hasFeatureAtPixel(evt.pixel) ? "pointer" : "";
  });
}

function mapOnSingleClick() {
  mapPanelOne.value.on("singleclick", function (evt) {
    let view = mapPanelOne.value.getView();
    let viewResolution = view.getResolution();
    let dataJson = layers.panorama.getSource().getFeatureInfoUrl(evt.coordinate, viewResolution, view.getProjection(), {
      INFO_FORMAT: "application/json",
    });
    let dataJsonDetection = null;

    if (dataJson) {
      fetch(dataJson)
        .then((response) => response.text())
        .then((json) => {
          let getDataJson = JSON.parse(json);

          if (getDataJson.features.length > 0) {
            if (state_remove) {
              let fPath = getDataJson.features[0];

              let svPath = new VectorSource({
                zIndex: 1,
                features: new GeoJSON().readFeatures(fPath, { featureProjection: mapPanelOne.value.getView().getProjection() }),
              });

              let lvPath = new VectorLayer({
                source: svPath,
                style: selectedStyle,
              });

              selected.push(lvPath);
              mapPanelOne.value.addLayer(lvPath);
            } else {
              // Ext.getCmp("mapviews").setLoading(true);
              loadPano(getDataJson.features[0].properties.id, true);
            }
          } else {
            dataJsonDetection = layers.detection.getSource().getFeatureInfoUrl(evt.coordinate, viewResolution, view.getProjection(), {
              INFO_FORMAT: "application/json",
            });

            if (dataJsonDetection) {
              fetch(dataJsonDetection)
                .then((response) => response.text())
                .then((json) => {
                  let getDataJsonDetection = JSON.parse(json);
                  if (getDataJsonDetection.features.length > 0) {
                    // Ext.getCmp("mapviews").setLoading(true);
                    loadPano(getDataJsonDetection.features[0].properties.id, false);
                  }
                });
            }
          }
        });
    }

    lonlat.value = transform(evt.coordinate, "EPSG:4326", "EPSG:4326");

    coordinates.lon = lonlat[0];
    coordinates.lat = lonlat[1];
  });
}

export function zoomIn() {
  var view = mapPanelOne.value.getView();
  var zoom = view.getZoom();
  view.setZoom(zoom + 1);
}

export function zoomOut() {
  var view = mapPanelOne.value.getView();
  var zoom = view.getZoom();
  view.setZoom(zoom - 1);
}

export function initLayers() {
  // toggleLayerJalan(isLayerShow.jalan);
  // toggleLayerBangunan(isLayerShow.bangunan);
  toggleLayerPanorama(isLayerShow.panorama);
  toggleLayerDetection(isLayerShow.detection);
  toggleLayerMapspot(isLayerShow.mapSpot);
}

// export function toggleLayerJalan(isChecked) {
//   if (isChecked === true) {
//     mapPanelOne.value.addLayer(layers.jalan);
//   } else {
//     mapPanelOne.value.removeLayer(layers.jalan);
//   }
// }

// export function toggleLayerBangunan(isChecked) {
//   if (isChecked === true) {
//     mapPanelOne.value.addLayer(layers.bangunan);
//   } else {
//     mapPanelOne.value.removeLayer(layers.bangunan);
//   }
// }

export function toggleLayerPanorama(isChecked) {
  if (isChecked === true) {
    mapPanelOne.value.addLayer(layers.panorama);
    if (other_panos_group !== null) {
      other_panos_group.visible = true;
    }
  } else {
    mapPanelOne.value.removeLayer(layers.panorama);
    if (other_panos_group !== null) {
      other_panos_group.visible = false;
    }
  }
}

export function toggleLayerDetection(isChecked) {
  if (isChecked === true) {
    mapPanelOne.value.addLayer(layers.detection);
  } else {
    mapPanelOne.value.removeLayer(layers.detection);
  }
}

export function toggleLayerMapspot(isChecked) {
  if (isChecked === true) {
    mapPanelOne.value.addLayer(layers.mapSpot);
    if (spotGroup !== null) {
      spotGroup.visible = true;
    }
  } else {
    mapPanelOne.value.removeLayer(layers.mapSpot);
    if (spotGroup !== null) {
      spotGroup.visible = false;
    }
  }
}

export function resizePanelOne() {
  mapPanelOne.value.updateSize();
}
