<script setup>
import { reactive } from "vue";
import { popupPanelTwo } from "@/modules/popup";
import { viewFixPanorama } from "@/stores/panorama";

const configData = reactive({
  yField: 0,
  xField: 0,
  hField: 2,
  rollField: 0,
  pitchField: 0,
  trackField: 0,
  noteField: "",
});

function fixChanged() {
  let correction = {
    dx: configData.xField,
    dy: configData.yField,
    dh: configData.hField,
    dr: configData.rollField,
    dp: configData.pitchField,
    dt: configData.trackField,
  };
  viewFixPanorama(correction);
}

function resetConfig() {
  configData.yField = 0;
  configData.xField = 0;
  configData.hField = 2;
  configData.rollField = 0;
  configData.pitchField = 0;
  configData.trackField = 0;
  configData.noteField = "";

  fixChanged();
}
</script>

<template>
  <Transition name="custom-classes" enter-active-class="animate__animated animate__fadeInRight animate__faster" leave-active-class="animate__animated animate__fadeOutRight animate__faster">
    <div class="card" v-if="popupPanelTwo.isConfigOpen">
      <div class="card-body">
        <form>
          <div>
            <label class="form-label">Drift Along track axis</label>
            <input type="number" @change="fixChanged" v-model="configData.yField" placeholder="0.00" step=".2" class="form-control form-control-sm" />
          </div>
          <div>
            <label class="form-label">Drift by side track axis</label>
            <input type="number" @change="fixChanged" v-model="configData.xField" placeholder="0.00" step=".2" class="form-control form-control-sm" />
          </div>
          <div>
            <label class="form-label">Height from ground</label>
            <input type="number" @change="fixChanged" v-model="configData.hField" placeholder="0.00" step=".2" class="form-control form-control-sm" />
          </div>
          <div>
            <label class="form-label">Roll angle</label>
            <input type="number" @change="fixChanged" v-model="configData.rollField" placeholder="0.00" step=".2" class="form-control form-control-sm" />
          </div>
          <div>
            <label class="form-label">Pitch angle</label>
            <input type="number" @change="fixChanged" v-model="configData.pitchField" placeholder="0.00" step=".2" class="form-control form-control-sm" />
          </div>
          <div>
            <label class="form-label">Track angle</label>
            <input type="number" @change="fixChanged" v-model="configData.trackField" placeholder="0.00" step=".2" class="form-control form-control-sm" />
          </div>
          <div>
            <label class="form-label">Note</label>
            <textarea v-model="configData.noteField" class="form-control form-control-sm" rows="3"></textarea>
          </div>
          <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-primary btn-sm mt-1">Keep</button>
            <button type="button" @click="resetConfig" class="btn btn-danger btn-sm mt-1">Discard</button>
          </div>
        </form>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.card {
  position: absolute;
  right: 0.2rem;
  bottom: 2.8rem;
}

.form-label {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
}
</style>
