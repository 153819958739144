import { ref, reactive } from "vue";

export const selectedPeriod = ref("");

export const params = reactive({
  project: null,
  year: null,
  vt: null,
});

export let getProject = function () {
  let ret = "";
  let currentUrl = window.location.href;
  let getUrl = new URL(currentUrl);
  let searchParams = new URLSearchParams(getUrl.search);

  ret = searchParams.get("project");

  return ret;
};

export let getYear = function () {
  let ret = "";
  let currentUrl = window.location.href;
  let getUrl = new URL(currentUrl);
  let searchParams = new URLSearchParams(getUrl.search);

  ret = searchParams.get("year");

  return ret;
};

export let getVt = function () {
  let ret = "";
  let currentUrl = window.location.href;
  let getUrl = new URL(currentUrl);
  let searchParams = new URLSearchParams(getUrl.search);

  if (getProject !== null && getYear() !== null) {
    params.project = getProject();
    params.year = getYear();
    params.vt = searchParams.get("vt");
    ret = params.vt;
  } else {
    window.location.href = url_admin;
  }

  return ret;
};

export let format = function (fmt, ...args) {
  return fmt.split("%%").reduce((aggregate, chunk, i) => aggregate + chunk + (args[i] || ""), "");
};

export function getDistanceFromLatLonInMetres(lat1, lon1, lat2, lon2) {
  var R = 6378.137; // Radius of earth in KM
  var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000; // meters
}
