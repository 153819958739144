<script setup>
import { showMapSpotPopup, isUpdateMapspot, notes, storeMapSpot, mapSpotData, removeMapSpot, enableNavigation } from "@/stores/panorama";

const { img_lon, img_lat, spot_x, spot_y, spot_z } = mapSpotData;
</script>

<template>
  <Transition name="custom-classes" enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">
    <div class="pin" v-if="showMapSpotPopup">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          Map Spot
          <button
            type="button"
            class="btn-close btn-sm"
            @click="
              showMapSpotPopup = false;
              enableNavigation(true);
            "
          ></button>
        </div>
        <div class="card-body px-2 py-1">
          <div class="my-1 mt-0">
            <label for="notes" class="form-label fw-bold fs-7 m-0">Notes</label>
            <textarea class="form-control" id="notes" rows="5" v-model="notes"></textarea>
          </div>
        </div>
        <div class="card-footer" id="card-footers">
          <button v-if="isUpdateMapspot === false" type="button" class="btn btn-primary" @click="storeMapSpot(img_lon, img_lat, spot_x, spot_y, spot_z)">Save</button>
          <div v-if="isUpdateMapspot === true" class="d-flex gap-1">
            <button type="button" class="btn btn-success">Update</button>
            <button
              type="button"
              class="btn btn-danger"
              @click="
                removeMapSpot();
                showMapSpotPopup = false;
                enableNavigation(true);
              "
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.pin {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  z-index: 10;
  .card-body {
    width: 15rem;
    overflow-y: auto;
  }
}
</style>
