<script setup>
import { onMounted } from "vue";
import ImageWMS from "ol/source/ImageWMS";
import ImageLayer from "ol/layer/Image";

// modules
import { popupPanelOne } from "@/modules/popup";
import { getLayers } from "@/modules/api";

// stores
import { layerLists } from "@/stores/layers";
import { isLayerShow, mapPanelOne, toggleLayerPanorama, toggleLayerDetection, toggleLayerMapspot } from "@/stores/map";

function toggleLayer(event, layer) {
  const createLayer = new ImageLayer({
    title: layer.name,
    source: new ImageWMS({
      url: layer.url,
      params: {
        LAYERS: layer.name_layer,
        TILED: true,
        exceptions: "application/vnd.ogc.se_inimage",
      },
      serverType: "geoserver",
    }),
  });

  if (event.target.checked) {
    mapPanelOne.value.addLayer(createLayer);
  } else {
    let layerTitle = layer.name;
    mapPanelOne.value.getLayers().forEach((layer) => {
      if (layer && layer.get("title") === layerTitle) {
        mapPanelOne.value.removeLayer(layer);
      }
    });
  }
}

onMounted(function () {
  getLayers();
});
</script>

<template>
  <Transition name="custom-classes" enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">
    <div class="layers" v-if="popupPanelOne.isLayersOpen">
      <div class="card">
        <div class="card-body px-2 py-1">
          <ul class="list-unstyled ps-0 mb-0">
            <li>
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#supported-data" aria-expanded="true">Supporting Data</button>
              <div class="collapse show" id="supported-data">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li class="form-check" v-for="layer in layerLists" :key="layer.id">
                    <input class="form-check-input" type="checkbox" :value="layer.name_layer" :id="layer.name" @change="(event) => toggleLayer(event, layer)" />
                    <label class="form-check-label user-select-none" :for="layer.name">{{ layer.name }}</label>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#panorama-data" aria-expanded="true">360 Data</button>
              <div class="collapse show" id="panorama-data">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li class="form-check">
                    <input class="form-check-input" v-model="isLayerShow.panorama" type="checkbox" value="panorama" id="panorama" @change="toggleLayerPanorama(isLayerShow.panorama)" />
                    <label class="form-check-label user-select-none" for="panorama">Panorama</label>
                  </li>
                  <li class="form-check">
                    <input class="form-check-input" v-model="isLayerShow.detection" type="checkbox" value="detection" id="detection" @change="toggleLayerDetection(isLayerShow.detection)" />
                    <label class="form-check-label user-select-none" for="detection">Detection</label>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#tagging-data" aria-expanded="true">Tagging Data</button>
              <div class="collapse show" id="tagging-data">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li class="form-check">
                    <input class="form-check-input" v-model="isLayerShow.mapSpot" type="checkbox" value="mapspot" id="mapspot" @change="toggleLayerMapspot(isLayerShow.mapSpot)" />
                    <label class="form-check-label user-select-none" for="mapspot">Mapspot</label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.layers {
  opacity: 0.8;
  position: absolute;
  left: 4.5rem;
  bottom: 2.5rem;
  z-index: 11;
  .card-body {
    width: 15rem;
    overflow-y: auto;
  }
}

.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  background-color: transparent;
  border: 0;
  &::before {
    width: 1em;
    line-height: 0;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
  }
}

.btn-toggle[aria-expanded="true"] {
  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
  }
}

.btn-toggle-nav {
  .form-check {
    margin-left: 1.7rem;
    font-size: 0.8rem;
  }
}
</style>
