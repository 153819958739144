import axiosClient from "@/modules/axios";
import { getProject, getYear } from "@/stores/var";
import { layerLists } from "@/stores/layers";

export async function getLayers() {
  const projectId = getProject();
  const year = getYear();
  const response = await axiosClient.get(`/layers/?project=${projectId}&year=${year}`);
  layerLists.value = response.data.results;
}
