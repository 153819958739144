import { reactive } from "vue";

export const popupPanelOne = reactive({
  isBasemapOpen: false,
  isLayersOpen: false,
  isMapMeasurementOpen: false,
});

export const popupPanelTwo = reactive({
  isPeriodOpen: false,
  isPanoramaInfoOpen: false,
  isConfigOpen: false,
});

export function toggleBasemap() {
  popupPanelOne.isBasemapOpen = !popupPanelOne.isBasemapOpen;
  popupPanelOne.isLayersOpen = false;
  popupPanelOne.isMapMeasurementOpen = false;
}

export function toggleLayers() {
  popupPanelOne.isLayersOpen = !popupPanelOne.isLayersOpen;
  popupPanelOne.isBasemapOpen = false;
  popupPanelOne.isMapMeasurementOpen = false;
}

export function toggleMeasurement() {
  popupPanelOne.isMapMeasurementOpen = !popupPanelOne.isMapMeasurementOpen;
  popupPanelOne.isLayersOpen = false;
  popupPanelOne.isBasemapOpen = false;
}

export function togglePeriod() {
  popupPanelTwo.isPeriodOpen = !popupPanelTwo.isPeriodOpen;
  popupPanelTwo.isConfigOpen = false;
  popupPanelTwo.isPanoramaInfoOpen = false;
}

export function togglePanoramaInfo() {
  popupPanelTwo.isPeriodOpen = false;
  popupPanelTwo.isConfigOpen = false;
  popupPanelTwo.isPanoramaInfoOpen = !popupPanelTwo.isPanoramaInfoOpen;
}

export function toggleConfig() {
  popupPanelTwo.isPeriodOpen = false;
  popupPanelTwo.isConfigOpen = !popupPanelTwo.isConfigOpen;
  popupPanelTwo.isPanoramaInfoOpen = false;
}
