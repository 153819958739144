<script setup>
import { onMounted, ref } from "vue";
import { Pane } from "splitpanes";

// stores
import { isLoading, zoomInFunction, zoomOutFunction, initPano, isPinOn, isShowAllSpot, isMapMeasurementOn, isPanoramaInfoOn, other_panos_group, spotGroup, removeImgObjMeasurement, removeSpotMeasurement, enableNavigation } from "@/stores/panorama";

// modules
import { togglePeriod, popupPanelTwo, toggleConfig, togglePanoramaInfo } from "@/modules/popup";

// components
import MapspotDialogVue from "@/components/MapspotDialog.vue";
import InfoDistance from "@/components/InfoDistance.vue";
import PanoramaInfoVue from "@/components/PanoramaInfo.vue";
import PeriodPopupVue from "@/components/PeriodPopup.vue";
import ConfigPopup from "@/components/ConfigPopup.vue";

const showBurgerMenu = ref(false);

function togglePin() {
  isPinOn.value = !isPinOn.value;
  popupPanelTwo.isConfigOpen = false;
  popupPanelTwo.isPanoramaInfoOpen = false;
  popupPanelTwo.isPeriodOpen = false;
}

function toggleAllSpot() {
  if (isShowAllSpot.value === true) {
    isShowAllSpot.value = false;
  } else {
    isShowAllSpot.value = true;
  }
  other_panos_group.visible = isShowAllSpot.value;
  spotGroup.visible = isShowAllSpot.value;
}

function toggleMeasurement() {
  isMapMeasurementOn.value = !isMapMeasurementOn.value;
  popupPanelTwo.isConfigOpen = false;
  popupPanelTwo.isPanoramaInfoOpen = false;
  popupPanelTwo.isPeriodOpen = false;

  if (isMapMeasurementOn.value === false) {
    removeImgObjMeasurement();
    removeSpotMeasurement();
    $("#distance").toast("hide");
  }
}

onMounted(() => {
  initPano();
});
</script>

<template>
  <pane class="card" id="panel-2">
    <div class="card-body p-0" id="panorama">
      <div v-if="isLoading" class="spinner-border text-light position-absolute top-50 start-50" role="status"></div>
      <InfoDistance />
    </div>
    <div class="card-footer d-flex justify-content-between">
      <ul class="d-flex m-0 p-0 gap-3">
        <li v-tippy="{ content: 'Menu' }" @click="showBurgerMenu = !showBurgerMenu" class="tooltip-burger-menu">
          <i class="fa-solid fa-bars cursor-pointer"></i>
        </li>
        <Transition name="custom-classes" enter-active-class="animate__animated animate__fadeInLeft animate__faster" leave-active-class="animate__animated animate__fadeOutLeft animate__faster">
          <div v-if="showBurgerMenu" class="d-flex m-0 p-0 gap-3">
            <li v-tippy="{ content: 'Period' }">
              <i class="fa-solid fa-clock cursor-pointer" @click="togglePeriod"></i>
            </li>
            <!-- <li v-tippy="{ content: 'Layer' }">
              <i class="fa-solid fa-layer-group cursor-pointer"></i>
            </li> -->
            <!-- <li>
              <i class="fa-solid cursor-pointer" :class="isShowAllSpot ? 'fa-eye text-primary' : 'fa-eye-slash'" @click="toggleAllSpot"></i>
            </li> -->
            <li v-tippy="{ content: 'Map Spot' }">
              <i class="fa-solid cursor-pointer" :class="isPinOn ? 'fa-ban text-primary' : 'fa-map-pin'" @click="togglePin"></i>
            </li>
            <li v-tippy="{ content: 'Measurement' }">
              <i class="fa-solid fa-ruler cursor-pointer" :class="isMapMeasurementOn ? 'text-primary' : ''" @click="toggleMeasurement"></i>
            </li>
            <li v-tippy="{ content: 'Information' }">
              <i class="fa-solid fa-circle-info cursor-pointer" :class="isPanoramaInfoOn ? 'text-primary' : ''" @click="togglePanoramaInfo"></i>
            </li>
            <li v-tippy="{ content: 'Config' }">
              <i class="fa-solid fa-gear cursor-pointer" @click="toggleConfig"></i>
            </li>
          </div>
        </Transition>
      </ul>
      <ul class="d-flex m-0 gap-3">
        <li v-tippy="{ content: 'Zoom In' }" @click="zoomInFunction">
          <i class="fa-solid fa-magnifying-glass-plus cursor-pointer"></i>
        </li>
        <li v-tippy="{ content: 'Zoom Out' }" @click="zoomOutFunction">
          <i class="fa-solid fa-magnifying-glass-minus cursor-pointer"></i>
        </li>
      </ul>
    </div>
    <MapspotDialogVue />
    <PeriodPopupVue />
    <PanoramaInfoVue v-if="!isLoading" />
    <ConfigPopup />
  </pane>
</template>
