<script setup>
import { measureDistance1, removeImgObjMeasurement } from "@/stores/panorama";

function closePopup() {
  $("#distance").toast("hide");
  removeImgObjMeasurement();
}
</script>

<template>
  <div class="toast" id="distance" role="alert" data-bs-autohide="false">
    <div class="toast-header">
      <strong class="me-auto">Distance</strong>
      <button type="button" class="btn-close" @click="closePopup"></button>
    </div>
    <div class="toast-body">{{ measureDistance1 }} Meter(s)</div>
  </div>
</template>

<style scoped>
.toast {
  position: absolute;
}
</style>
