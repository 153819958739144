import { createApp } from "vue";
import VueTippy from "vue-tippy";
import App from "./App.vue";
import "ol/ol.css";
import "../public/scss/style.scss";
import "splitpanes/dist/splitpanes.css";
import "tippy.js/dist/tippy.css";
import "animate.css";

createApp(App).use(VueTippy).mount("#app");
