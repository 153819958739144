<script setup>
import { popupPanelOne, toggleMeasurement } from "@/modules/popup";
import { disableRemoveMeasurement, addLineStringMeasurement, addAreaMeasurement, addInteraction } from "@/stores/measurement";
import { watch, ref, onMounted } from "vue";

const selectedRadioMeasurement = ref("clear");

watch(selectedRadioMeasurement, function () {
  if (selectedRadioMeasurement.value === "length") {
    addLineStringMeasurement();
  } else if (selectedRadioMeasurement.value === "area") {
    addAreaMeasurement();
  } else {
    disableRemoveMeasurement();
  }
});
</script>

<template>
  <Transition name="custom-classes" enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">
    <div class="measurement" v-if="popupPanelOne.isMapMeasurementOpen">
      <div class="card">
        <!-- <div class="card-header d-flex justify-content-between align-items-center">
          Measurement
          <button type="button" class="btn-close btn-sm" @click="toggleMeasurement"></button>
        </div> -->
        <div class="card-body px-2 py-1">
          <div class="form-check d-flex align-items-center gap-1">
            <input v-model="selectedRadioMeasurement" value="clear" class="form-check-input fs-7" name="flexRadioDefault" type="radio" id="clearMeasurement" />
            <label class="form-check-label fs-7" for="clearMeasurement">Clear Measurement</label>
          </div>
          <div class="form-check d-flex align-items-center gap-1">
            <input v-model="selectedRadioMeasurement" value="length" class="form-check-input fs-7" name="flexRadioDefault" type="radio" id="length" />
            <label class="form-check-label fs-7" for="length">Length (Line String)</label>
          </div>
          <div class="form-check d-flex align-items-center gap-1">
            <input v-model="selectedRadioMeasurement" value="area" class="form-check-input fs-7" name="flexRadioDefault" type="radio" id="area" />
            <label class="form-check-label fs-7" for="area">Area (Polygon)</label>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.measurement {
  opacity: 0.8;
  position: absolute;
  left: 6.5rem;
  bottom: 2.5rem;
  z-index: 10;
  .card-body {
    width: 15rem;
    overflow-y: auto;
  }
}
</style>
