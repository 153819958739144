<script setup>
import { watch } from "vue";
import XYZ from "ol/source/XYZ";
import OSM from "ol/source/OSM";
import { layers, selectedRadio } from "@/stores/map";

// modules
import { popupPanelOne, toggleBasemap } from "@/modules/popup";

const baseMaps = [
  {
    label: "Open Street Map",
    value: "openStreetMap",
  },
  {
    label: "Google Satellite",
    value: "googleSatellite",
  },
  {
    label: "Google Road Map",
    value: "googleRoadMap",
  },
  {
    label: "Google Hybrid",
    value: "googleHybrid",
  },
  {
    label: "Google Terrain",
    value: "googleTerrain",
  },
  {
    label: "Esri World Imagery",
    value: "esriWorldImagery",
  },
  {
    label: "Esri World Street Map",
    value: "esriWorldStreetMap",
  },
  {
    label: "Esri World Topo Map",
    value: "esriWorldTopoMap",
  },
  {
    label: "Esri Gray Map",
    value: "esriGrayMap",
  },
];

watch(selectedRadio, () => {
  if (selectedRadio.value === "openStreetMap") {
    const source = new OSM();
    layers.initTileLayer.setSource(source);
  } else if (selectedRadio.value === "googleSatellite") {
    const source = new XYZ({
      url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    });
    layers.initTileLayer.setSource(source);
  } else if (selectedRadio.value === "googleRoadMap") {
    const source = new XYZ({
      url: "https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}",
    });
    layers.initTileLayer.setSource(source);
  } else if (selectedRadio.value === "googleHybrid") {
    const source = new XYZ({
      url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
    });
    layers.initTileLayer.setSource(source);
  } else if (selectedRadio.value === "googleTerrain") {
    const source = new XYZ({
      url: "https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
    });
    layers.initTileLayer.setSource(source);
  } else if (selectedRadio.value === "esriWorldImagery") {
    const source = new XYZ({
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    });
    layers.initTileLayer.setSource(source);
  } else if (selectedRadio.value === "esriWorldStreetMap") {
    const source = new XYZ({
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
    });
    layers.initTileLayer.setSource(source);
  } else if (selectedRadio.value === "esriWorldTopoMap") {
    const source = new XYZ({
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
    });
    layers.initTileLayer.setSource(source);
  } else if (selectedRadio.value === "esriGrayMap") {
    const source = new XYZ({
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
    });
    layers.initTileLayer.setSource(source);
  }
});
</script>

<template>
  <Transition name="custom-classes" enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">
    <div class="basemap" v-if="popupPanelOne.isBasemapOpen">
      <div class="card">
        <!-- <div class="card-header d-flex justify-content-between align-items-center">
          Basemap
          <button type="button" class="btn-close btn-sm" @click="toggleBasemap"></button>
        </div> -->
        <div class="card-body px-2 py-1">
          <div class="form-check d-flex align-items-center gap-1" v-for="baseMap in baseMaps">
            <input v-model="selectedRadio" :value="baseMap.value" class="form-check-input fs-7" name="flexRadioDefault" type="radio" :id="baseMap.value" />
            <label class="form-check-label fs-7" :for="baseMap.value">{{ baseMap.label }}</label>
          </div>
          <!-- <div class="form-check d-flex align-items-center gap-1">
            <input v-model="selectedRadio" value="googleSatellite" class="form-check-input fs-7" name="flexRadioDefault" type="radio" id="googleSatellite" />
            <label class="form-check-label fs-7" for="googleSatellite">Google Satellite</label>
          </div>
          <div class="form-check d-flex align-items-center gap-1">
            <input v-model="selectedRadio" value="googleSatellite" class="form-check-input fs-7" name="flexRadioDefault" type="radio" id="googleSatellite" />
            <label class="form-check-label fs-7" for="googleSatellite">Google Satellite</label>
          </div> -->
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.basemap {
  opacity: 0.8;
  position: absolute;
  left: 2.5rem;
  bottom: 2.5rem;
  z-index: 10;
  .card-body {
    width: 15rem;
    overflow-y: auto;
  }
}
</style>
