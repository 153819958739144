<script setup>
import { onMounted, ref } from "vue";
import { Pane } from "splitpanes";

import { initMap, initLayers, zoomIn, zoomOut } from "@/stores/map";

// modules
import { popupPanelOne, toggleBasemap, toggleLayers, toggleMeasurement } from "@/modules/popup";

// components
import BasemapPopupVue from "@/components/BasemapPopup.vue";
import LayersPopupVue from "@/components/LayersPopup.vue";
import MeasurementPopupVue from "@/components/MeasurementPopup.vue";

const showBurgerMenu = ref(false);

onMounted(() => {
  initMap();
  initLayers();
});
</script>

<template>
  <pane class="card" id="panel-1">
    <div class="card-body d-flex flex-grow-1 p-0 w-100" id="mapPanelOne"></div>
    <div class="card-footer d-flex justify-content-between">
      <ul class="d-flex m-0 p-0 gap-3">
        <li v-tippy="{ content: 'Menu' }" @click="showBurgerMenu = !showBurgerMenu" class="tooltip-burger-menu">
          <i class="fa-solid fa-bars cursor-pointer"></i>
        </li>
        <Transition name="custom-classes" enter-active-class="animate__animated animate__fadeInLeft animate__faster" leave-active-class="animate__animated animate__fadeOutLeft animate__faster">
          <div v-if="showBurgerMenu" class="d-flex m-0 p-0 gap-3">
            <li v-tippy="{ content: 'Basemap' }">
              <i class="fa-solid fa-map cursor-pointer" :class="popupPanelOne.isBasemapOpen ? 'text-primary' : ''" @click="toggleBasemap"></i>
            </li>
            <li v-tippy="{ content: 'Layers' }">
              <i class="fa-solid fa-layer-group cursor-pointer" :class="popupPanelOne.isLayersOpen ? 'text-primary' : ''" @click="toggleLayers"></i>
            </li>
            <li v-tippy="{ content: 'Measurement Type' }">
              <i class="fa-solid fa-ruler cursor-pointer" :class="popupPanelOne.isMapMeasurementOpen ? 'text-primary' : ''" @click="toggleMeasurement"></i>
            </li>
            <!-- <li id="tooltip-download-mapspot">
              <i class="fa-solid fa-download cursor-pointer"></i>
            </li> -->
          </div>
        </Transition>
      </ul>
      <ul class="d-flex m-0 gap-3">
        <li v-tippy="{ content: 'Zoom In' }">
          <i class="fa-solid fa-magnifying-glass-plus cursor-pointer" @click="zoomIn"></i>
        </li>
        <li v-tippy="{ content: 'Zoom Out' }">
          <i class="fa-solid fa-magnifying-glass-minus cursor-pointer" @click="zoomOut"></i>
        </li>
      </ul>
    </div>
    <BasemapPopupVue />
    <LayersPopupVue />
    <MeasurementPopupVue />
  </pane>
</template>

<!-- <script>
export default {
  name: "ShellExec",
  components: {},
  data() {
    return {
      shell_command: "",
    };
  },
  methods: {
    async shell() {
      return new Promise((resolve, reject) => {
        exec(this.shell_command, (err, stdout, stderr) => {
          if (err) {
            reject(err);
          } else {
            resolve({ stdout, stderr });
          }
        });
      });
    },

    async shellExec() {
      let { stdout } = await this.shell();
      for (let line of stdout.split("\n")) {
        console.log(`ls: ${line}`);
      }
    },
  },
};
</script> -->

<style>
#mapPanelOne {
  z-index: 1;
  /* width: 100%; */
}

.user-select-none {
  user-select: none;
}
</style>
