<script setup>
import { Splitpanes } from "splitpanes";
import Panel1Vue from "@/components/Panel1.vue";
import Panel2Vue from "@/components/Panel2.vue";

import { resizePanelOne } from "@/stores/map";
import { renderResize } from "@/stores/panorama";

function runRenderResize() {
  resizePanelOne();
  renderResize();
}
</script>

<template>
  <div class="d-flex flex-column" style="height: 100vh">
    <div class="logo-digireg">
      <img src="../public/logo.png" alt="logo" />
    </div>
    <splitpanes class="default-theme" @resize="runRenderResize">
      <Panel1Vue />
      <Panel2Vue />
    </splitpanes>
  </div>
</template>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
