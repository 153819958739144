import axios from "axios";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const token = urlParams.get("vt");

const accessToken = token;

const axiosClient = axios.create({
  baseURL: `https://backend.observer.co.id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
});

export default axiosClient;
