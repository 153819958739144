<script setup>
import { onMounted, ref } from "vue";
import { popupPanelTwo, togglePeriod } from "@/modules/popup";
import { selectedPeriod, getYear } from "@/stores/var";

// const periods = ["none", "Jan 2021", "Feb 2021", "Mar 2021", "Apr 2021", "May 2021", "Jun 2021", "Jul 2021", "Aug 2021", "Sep 2021", "Oct 2021", "Nov 2021", "Des 2021"];
const periods = ["2021", "2022","2023"];

function reloadPage(e) {
  periods.filter((period) => {
    if (e.target.value == period) {
      const url = new URL(window.location.href);
      url.searchParams.set("year", e.target.value);
      window.history.replaceState(null, null, url);
      window.location = url;
    }
  });
}

onMounted(function () {
  selectedPeriod.value = getYear();
});
</script>

<template>
  <Transition name="custom-classes" enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">
    <div class="period" v-if="popupPanelTwo.isPeriodOpen">
      <div class="card">
        <!-- <div class="card-header">
          <button type="button" class="btn-close btn-sm" @click="togglePeriod"></button>
        </div> -->
        <div class="card-body px-2 py-1">
          <div class="form-check d-flex align-items-center gap-1" v-for="period in periods">
            <input class="form-check-input fs-7" :value="period" v-model="selectedPeriod" name="flexRadioDefault" type="radio" @change="(e) => reloadPage(e)" />
            <label class="form-check-label fs-7">{{ period }}</label>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.period {
  opacity: 0.8;
  position: absolute;
  left: 2.5rem;
  bottom: 2.5rem;
  z-index: 10;
  .card-body {
    width: 15rem;
    overflow-y: auto;
  }
}
</style>
